import { defineStore } from 'pinia'

import ProductService from '@/services/ProductService'

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useCurrentProductStore = defineStore('currentProduct', {
  state: () => ({
    currentProduct: null,
    selectedColor: null,
    selectedDimensions: null,
    amount: 1,
    variantId: null,
  }),
  getters: {
    divideBy() {
      if (this.currentProduct) {
        if (this.currentProduct.dimension_units === 'mm')
          return 1
        if (this.currentProduct.dimension_units === 'cm')
          return 10
        if (this.currentProduct.dimension_units === 'm')
          return 1000
      }
      return 1
    },
    relevantDimensions() {
      if (this.currentProduct)
        return !this.currentProduct?.relevant_dimensions || this.currentProduct?.relevant_dimensions?.length === 0 ? ['height', 'width'] : this.currentProduct?.relevant_dimensions
      return []
    },
    price() {
      if (this.selectedVariant) {
        if (this.selectedVariant.prices) {
          const sortedPrices = this.selectedVariant.prices?.sort((a, b) => b.amount - a.amount)
          let currentSelectedPrice = null
          sortedPrices?.forEach((newPrice) => {
            if (currentSelectedPrice === null && newPrice.amount <= this.amount)
              currentSelectedPrice = newPrice
            if (newPrice.amount <= this.amount && (currentSelectedPrice?.amount && newPrice.amount > currentSelectedPrice.amount))
              currentSelectedPrice = newPrice
          })
          return currentSelectedPrice
        }
        if (this.selectedVariant.price)
          return { price: this.selectedVariant.price }
      }
      return null
    },
    allVariantImages() {
      if (this.currentProduct) {
        if (!this.selectedVariant) {
          const variantImages = [...new Set(...this.currentProduct?.variants?.map(x => [...x.images]))]
          const productImages = [...new Set(this.currentProduct?.images)]
          return [...new Set([...productImages, ...variantImages])]
        }
        else {
          let variantImages = null
          if (this.selectedVariant?.images.length !== 0)
            variantImages = [...new Set(this.selectedVariant?.images)]
          else
            variantImages = [...new Set(this.currentProduct?.images)]

          return [...new Set([...variantImages])]
        }
      }
    },
    allVariantAttachments() {
      if (this.currentProduct) {
        let allVariantAttachments = []
        this.currentProduct?.variants?.forEach((x) => {
          allVariantAttachments.push(...x.attachments)
        })

        allVariantAttachments = allVariantAttachments.filter((value, index, self) =>
          index === self.findIndex(t => (
            t.title === value.title && t.locale === value.locale
          )),
        )
        const productAttachments = [...new Set(this.currentProduct?.attachments)]
        return [...new Set([...productAttachments, ...allVariantAttachments])]
      }
    },
    getCurrentProduct() {
      return this.currentProduct
    },
    getCurrentColor() {
      if (this.currentProductColors?.length === 1)
        return this.currentProductColors[0]
      return this.selectedColor
    },
    getCurrentDimensions() {
      return this.selectedDimensions
    },
    getAmount() {
      return this.amount
    },
    currentProductColors() {
      if (this.currentProduct) {
        const colors = [...new Set(this.currentProduct?.variants?.map(x => x?.color))]
        const uniqueColors = []
        colors.forEach((color) => {
          if (!uniqueColors.find(x => color?.code === x?.code && color?.title === x.title))
            uniqueColors.push(color)
        })
        return uniqueColors
      }
    },
    currentVariants() {
      if (this.currentProduct)
        return this.currentProduct.variants
    },
    currentProductDimensions() {
      if (this.currentProduct) {
        const dimensions = [...new Set(this.currentProduct.variants.map((x) => {
          const returnObject = {}
          this.relevantDimensions.forEach((dimension) => {
            returnObject[dimension] = x[dimension]
          })
          return returnObject
        }),
        )]
        const uniqueDimensions = []
        dimensions.forEach((dimension) => {
          const existingDimension = uniqueDimensions.filter((x) => {
            let dimensionsDifferent = true
            this.relevantDimensions.forEach((relevantDimension) => {
              if (dimension[relevantDimension] !== x[relevantDimension])
                dimensionsDifferent = false
            })
            return dimensionsDifferent
          })

          if (existingDimension.length === 0)
            uniqueDimensions.push(dimension)
        })
        return uniqueDimensions
      }
    },
    possibleColors() {
      if (this.selectedDimensions) {
        return [...new Set(
          this.currentProduct.variants.filter((x) => {
            let possibleVariant = true
            this.relevantDimensions.forEach((dimension) => {
              if (this.selectedDimensions[dimension] !== x[dimension])
                possibleVariant = false
            })
            return possibleVariant
          }).map(
            (x) => {
              return x.color
            },
          ),
        ),
        ]
      }
      return this.currentProductColors
    },
    allPossibleColors() {
      const colors = [...new Set(this.currentProduct?.variants?.map(x => x?.color))]
      return colors
    },
    possibleDimensions() {
      if (this.selectedColor) {
        return [...new Set(
          this.currentProduct.variants.filter((x) => {
            return this.selectedColor?.code === x.color?.code && this.selectedColor?.title === x.color?.title
          }).map(
            (x) => {
              const returnObject = {}
              this.relevantDimensions.forEach((dimension) => {
                returnObject[dimension] = x[dimension]
              })
              return returnObject
            },
          ),
        ),
        ]
      }
      return this.currentProductDimensions
    },
    selectedVariant() {
      if (this.variantId)
        return this.currentProduct.variants.find(x => x.id === this.variantId)

      if (this.selectedDimensions) {
        return this.currentProduct.variants.find((x) => {
          let possibleVariant = true
          this.relevantDimensions.forEach((dimension) => {
            if (this.selectedDimensions[dimension] !== x[dimension])
              possibleVariant = false
          })
          if (this.selectedColor !== null) {
            if (x?.color?.code !== this.selectedColor?.code || x?.color?.title !== this.selectedColor?.title)
              possibleVariant = false
          }
          else if (x?.color !== null) { return false }

          return possibleVariant
        },
        )
      }
      if (this.currentProductDimensions.filter(x => Object.values(x).filter(y => y !== 0).length !== 0).length === 0) {
        return this.currentProduct.variants.find((x) => {
          if (this.selectedColor !== null) {
            if (x?.color?.code !== this.selectedColor?.code || x?.color?.title !== this.selectedColor?.title)
              return false
          }
          else if (x?.color !== null) { return false }
          return true
        })
      }
      return null
    },
  },
  actions: {
    divideByUnit(dimensionType) {
      let dimensionUnit = this.currentProduct.dimension_units[dimensionType]
      if (!dimensionUnit) {
        if (typeof this.currentProduct.dimension_units !== 'string')
          dimensionUnit = this.currentProduct.dimension_units.default
        else
          dimensionUnit = this.currentProduct.dimension_units
      }
      if (!dimensionUnit)
        dimensionUnit = 'mm'
      if (dimensionUnit) {
        if (dimensionUnit === 'mm')
          return 1
        if (dimensionUnit === 'cm')
          return 10
        if (dimensionUnit === 'm')
          return 1000
      }
      return 1
    },
    fetchCurrentProduct(id) {
      this.clearVariant()
      const fetchedProduct = ProductService.get(id)
      if (fetchedProduct) {
        this.currentProduct = fetchedProduct
        this.initCurrentProduct()
      }
    },
    setCurrentProduct(product) {
      this.clearVariant()
      this.currentProduct = product
      this.initCurrentProduct()
    },
    initCurrentProduct() {
      if (!this.currentProduct)
        return
      if (!this.currentProduct.dimension_units) {
        this.currentProduct.dimension_units = {
          default: 'mm',
        }
      }
      if (typeof this.currentProduct.dimension_units === 'string') {
        this.currentProduct.dimension_units = {
          default: this.currentProduct.dimension_units,
        }
      }
    },
    selectColor(color) {
      this.variantId = null
      if (this.selectedColor === color)
        this.selectedColor = null
      else
        this.selectedColor = color
    },
    selectVariant(variant) {
      this.selectedColor = variant.color
      this.variantId = variant.id
      if (!this.selectedDimensions)
        this.selectedDimensions = {}
      this.relevantDimensions.forEach((dimension) => {
        this.selectedDimensions[dimension] = variant[dimension]
      })
    },
    clearVariant() {
      this.selectedColor = null
      this.selectedDimensions = null
      this.variantId = null
    },

    selectDimensions(size) {
      let alreadySelected = true
      this.variantId = null
      this.relevantDimensions.forEach((dimension) => {
        if (!this.selectedDimensions || size[dimension] !== this.selectedDimensions[dimension])
          alreadySelected = false
      })

      if (alreadySelected) { this.selectedDimensions = null }
      else {
        if (!this.selectedDimensions)
          this.selectedDimensions = {}
        this.relevantDimensions.forEach((dimension) => {
          this.selectedDimensions[dimension] = size[dimension]
        })
      }
    },
    setAmount(amount) {
      if (amount > this.selectedVariant.stock)
        this.amount = this.selectedVariant.stock
      else
        this.amount = amount
    },
  },
})
